// Wizard styles
 .step-wrapper {
     padding: 0;
     display: none;

     &.active {
         display: block;
     }
 }



 .step-indicator {
     border-collapse: separate;
     display: table;
     margin-left: 0px;
     position: relative;
     table-layout: fixed;
     text-align: center;
     vertical-align: middle;
     padding-left: 0;
     padding-top: 20px;

     li {
         display: table-cell;
         position: relative;
         float: none;
         padding: 0;
         width: 1%;

         &:after {
             background-color: $wizard-color-neutral;
             content: "";
             display: block;
             height: 1px;
             position: absolute;
             width: 100%;
             top: $wizard-step-width-height/2;
         }

         &:after {
             left: 50%;
         }

         &:last-child {
             &:after {
                 display: none;
             }
         }

         &.active {
             .step {
                 border-color: $wizard-color-active;
                 color: $wizard-color-active;
             }

             .caption {
                 color: $wizard-color-active;
             }
         }

         &.complete {
             &:after {
                 background-color: $wizard-color-complete;
             }

             .step {
                 border-color: $wizard-color-complete;
                 color: $wizard-color-complete;
             }

             .caption {
                 color: $wizard-color-complete;
             }
         }
     }

     .step {
         background-color: #fff;
         border-radius: 50%;
         border: 1px solid $wizard-color-neutral;
         color: $wizard-color-neutral;
         font-size: $wizard-step-font-size;
         height: $wizard-step-width-height;
         line-height: $wizard-step-width-height;
         margin: 0 auto;
         position: relative;
         width: $wizard-step-width-height;
         z-index: 1;

         &:hover {
             cursor: pointer;
         }
     }

     .caption {
         color: $wizard-color-neutral;
         padding: 11px 16px;
     }
 }

// Form styles
 label.required:after {
     content: " *";
     color: red;
 }

 label.required_nogap:after {
     content: "*";  /* brings the red star closer to the label */
     color: red;
 }

 .has-error .form-control {
     background-color: #fbe8e6;
 }

 // General helper styles
 .event-page {
     label.required:after {
        content: " *";
        color: orange;
        margin-bottom: 0;
     }
 }

 .welcome-page {
    .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited, .btn-primary:focus{
        background-color: #9e0039 !important;
        border-color: #9e0039 !important;
    }
 }
 