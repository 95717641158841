
 
.infobox {
  border: 1px solid #BFBFBF;
  background-image: url('/images/cross-bg.png');
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  padding-bottom:35px;
}

.infobox p {
  text-shadow: 0 0 black;
}

.arabic {
  font-family: sans-serif;
  direction: rtl;

  .list-unstyled{
    padding-right: 0;
  }
}

.footer {
  margin:50px 0;
  text-align: center;
}

.bg-cross {
  background-image: url('/images/cross-bg.png') ;
}

.navbar-brand {
  padding: 0px;
}

.navbar-brand>img {
  height: 100%;
  padding: 5px 15px;
  width: auto;
}
